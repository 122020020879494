var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-height align-start" },
    [
      [
        _c("app-header", { attrs: { title: _vm.title } }),
        _c(
          "v-main",
          [
            _c(
              "v-container",
              { attrs: { fluid: "" } },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("span", { attrs: { id: "pdf-print-ready" } }),
                        _c("router-view", {
                          attrs: { mode: "print" },
                          on: { updateTitle: _vm.updateTitle }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("app-footer")
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }