<template>
    <div class="fill-height align-start">
        <template>
            <app-header :title="title"></app-header>
            <v-main>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <span id="pdf-print-ready"></span>
                            <router-view
                                @updateTitle="updateTitle"
                                mode="print"
                            ></router-view>
                        </v-col>
                    </v-row>
                </v-container>
            </v-main>
            <app-footer></app-footer>
        </template>
    </div>
</template>

<script>
export default {
    name: "print-layout",
    components: {
        AppHeader: () => import("./AppHeader.vue"),
        AppFooter: () => import("../auth/AppFooter"),
    },
    data: () => ({
        title: "",
    }),
    computed: {},
    created() {},
    mounted() {},
    methods: {
        updateTitle(title) {
            this.title = title;
        },
    },
};
</script>

<style>
.page {
    page-break-after: always;
}
</style>

